import React, { useState, useEffect, useMemo } from 'react';
import { orderBy } from 'lodash';
import { useLocation } from '@reach/router';
import loadable from '@loadable/component';

import { Product, ProductAttributeValues } from 'services/models';
import styles from './products.module.scss';
import {
  trackProductClick,
  trackProductImpressions,
  isProductOfAttributeValue,
} from '../../utils';
import Breadcrumbs, { BreadcrumbItem } from 'components/Breadcrumbs';
import { getcategories } from 'services/categories.api';
import { graphql, navigate } from 'gatsby';
import catIcon from '../../assets/svg/cat.svg';
import dogIcon from '../../assets/svg/dog.svg';
import MainLayout from '../../layouts/mainLayout';
const ProductItem = loadable(() => import('components/ProductItem'));
const JoinFurFamily = loadable(() => import('components/JoinFurFamily'));

enum SortOptions {
  'Alphabetical' = 'Alphabetical',
  'PriceLow' = 'PriceLow',
  'PriceHigh' = 'PriceHigh',
}

export default (props: any) => {
  const { data } = props;
  const { allRestApiApiProducts } = data;
  const [allProducts, setAllProducts] = useState([]);
  useEffect(() => {
    const finalData = (allRestApiApiProducts?.nodes || []).map(it => {
      const id = it.endpointId;
      delete it.endpointId;
      return { ...it, id };
    });
    setAllProducts(finalData);
  }, [data]);

  const [hasTracked, setHasTracked] = useState(false);
  const [productsList, setProductsList] = useState(allProducts || []);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>();
  const [sortCondition, setSortCondition] = useState(SortOptions.Alphabetical);
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
  const [subCategories, setSubCategories] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const productsToShow = allProducts.filter(
      item =>
        item.is_public &&
        isProductOfAttributeValue(item, ProductAttributeValues.ONE_OFF),
    );
    setProductsList(orderBy(productsToShow, ['title']));

    if (allProducts.length && !hasTracked) {
      trackProductImpressions(orderBy(productsToShow, ['title']));
      setHasTracked(true);
    }
  }, [allProducts]);
  const categoriesWithProducts = useMemo(() => {
    return allProducts
      .map(it => it.categories)
      .flat(1)
      .filter(
        (value, index, self) =>
          index === self.findIndex(t => t.id === value.id),
      )
      .map(({ name, slug, id }) => ({ name, slug, id }));
  }, [allProducts]);
  const getItemFromLocation = (key = '') => {
    const search = new URLSearchParams(location.search);
    return search.get(key);
  };
  useEffect(() => {
    if (location) {
      let searchableItem = 'products';
      if (location.search) {
        setSubCategories([]);
        const search = new URLSearchParams(location.search);
        const searchedItem = search.get('category');
        getcategories(searchedItem ? searchedItem : '').then(res => {
          setSubCategories(
            res.data.filter(it =>
              categoriesWithProducts.find(cat => cat.id === it.id),
            ),
          );
        });
        searchableItem = searchedItem ? searchedItem : searchableItem;
      } else {
        getcategories('').then(res => {
          setSubCategories(res.data);
        });
      }
      const breadcrumbItems: BreadcrumbItem[] = [
        {
          name: 'Products',
          link: '/products/',
        },
      ];
      if (
        searchableItem === 'products' &&
        productsList &&
        productsList.length
      ) {
        setFilteredProducts(productsList);
      } else {
        let areAncestorsFound = false;
        const newFilteredProducts = productsList.filter(product => {
          if (
            product.categories &&
            product.categories.length &&
            product.categories.filter(it => it.ancestors).length
          ) {
            // const childCategory = product.categories[0].ancestors.find(
            //   category => category.slug === searchableItem,
            // );
            const childCategories = product.categories
              .map(it =>
                it.ancestors.find(category => category.slug === searchableItem),
              )
              .filter(it => it);
            const childCategory = childCategories.length
              ? childCategories.find(it => it.slug === searchableItem)
              : null;
            if (childCategory) {
              if (!areAncestorsFound) {
                const selectedCatgory = product.categories.find(
                  it => it.slug === searchableItem,
                );
                (selectedCatgory || product.categories[0]).ancestors.forEach(
                  category => {
                    if (!areAncestorsFound) {
                      breadcrumbItems.push({
                        name: category.name,
                        link: '/products/?category=' + category.slug,
                      });
                      if (category.slug === childCategory.slug) {
                        areAncestorsFound = true;
                      }
                    }
                  },
                );
              }
              return true;
            }
          }
        });
        setFilteredProducts(newFilteredProducts);
      }
      setBreadcrumbs(breadcrumbItems);
    }
  }, [location, productsList]);

  const onSortChanged = (option: SortOptions) => {
    let orderedList = [...productsList];
    switch (option) {
      case SortOptions.Alphabetical:
        orderedList = orderBy(productsList, ['title']);
        break;
      case SortOptions.PriceHigh:
        orderedList = orderBy(productsList, obj => Number(obj.price.incl_tax), [
          'desc',
        ]);
        break;
      case SortOptions.PriceLow:
        orderedList = orderBy(productsList, obj => Number(obj.price.incl_tax), [
          'asc',
        ]);
        break;
      default:
        break;
    }

    setProductsList(orderedList);
    setSortCondition(option);
  };
  const subCategoriesContainer = () => {
    return (
      <div className={styles.subCategories}>
        {subCategories
          .filter(it => !['main-subscription-products'].includes(it.slug))
          .map((it, index) => (
            <span
              className={styles.commonTags}
              key={'subcategories' + index}
              onClick={() => navigate('/products/?category=' + it.slug)}
            >
              {['dog', 'cat'].includes(it.slug) && (
                <img
                  height={16}
                  width={16}
                  src={it.slug === 'dog' ? dogIcon : catIcon}
                />
              )}
              {it.name}
            </span>
          ))}
      </div>
    );
  };
  useEffect(() => {
    // if (!location.search) {
    //   let path = window.location.pathname;
    //   path = path.replace('/products/', '');
    //   if (path) {
    //     navigate('/products/');
    //   }
    // }
  });
  return (
    <MainLayout>
      <div className={styles.productWrapper}>
        <div className={styles.titleWrapper}>
          <h2 className="mgn-bot-20">Shop One-Off Furbaby Essentials</h2>
          <p className={styles.subtitle}>
            Quality products designed with people, pets and planet in mind.
            <br /> Shop one-off items below, or click{' '}
            <a href="/getstarted">get started</a> to save time and money with a
            personalised feeding plan.
          </p>
        </div>
        <div className={styles.sortbyWrapper}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <div className={styles.actionContainer}>
            <div className={styles.subCategoriesWeb}>
              {subCategoriesContainer()}
            </div>
            <select
              className={styles.selectBox}
              value={sortCondition}
              onChange={e => onSortChanged(e.target.value as SortOptions)}
            >
              <option value={SortOptions.Alphabetical}>Alphabetical</option>
              <option value={SortOptions.PriceHigh}>Price - high to low</option>
              <option value={SortOptions.PriceLow}>Price - low to high</option>
            </select>
          </div>
        </div>
        <div className={styles.subCategoriesMobile}>
          {subCategoriesContainer()}
        </div>
        <div className={styles.productsListWrapper}>
          {filteredProducts?.map((item, index) => (
            <ProductItem
              key={item.id}
              productData={item}
              onProductPress={(data: Product) => {
                navigate(`/products/${item.slug}`, {
                  state: { parent: getItemFromLocation('category') },
                });
                trackProductClick(data, index);
              }}
            />
          ))}
        </div>
      </div>
      <JoinFurFamily page_id="products_page_footer" />
    </MainLayout>
  );
};
export const query = graphql`
  query {
    allRestApiApiProducts {
      nodes {
        title
        id
        endpointId
        image
        is_public
        is_subscribable
        is_suggestible
        items_in_cart_limit
        percentage
        price {
          currency
          excl_tax
          incl_tax
          tax
        }
        product_attributes {
          code
          name
          value
        }
        product_class
        product_type
        shipping_charge
        slug
        subscription_price
        weightage
        url
        description
        categories {
          id
          name
          slug
          ancestors {
            id
            name
            slug
          }
          descendants {
            id
            name
            slug
          }
        }
      }
      totalCount
    }
  }
`;