import React from 'react';
import { Link } from 'gatsby';

export interface BreadcrumbItem {
  link: string;
  name: string;
}

interface Props {
  breadcrumbs: BreadcrumbItem[];
}

export default (props: Props) => {
  if (!props.breadcrumbs) {
    return null;
  }
  return (
    <ul className="product-breadcrumb">
      {props.breadcrumbs.length >= 2 &&
        props.breadcrumbs?.map((breadcrumb, index) => (
          <li>
            <Link
              to={breadcrumb.link}
              getProps={() =>
                index === props.breadcrumbs.length - 1
                  ? { className: 'active' }
                  : {}
              }
              className="breadcrumb-item"
            >
              {breadcrumb.name}
            </Link>
          </li>
        ))}
    </ul>
  );
};
