import axiosCustom, { CustomResponse } from './customAxios';
import { Categories } from './models/category';

const path = 'categories/';

export const getcategories = async (parent?: string): CustomResponse<Categories[]> => {

  const { data: categories, error } = await axiosCustom.get(path + `${parent ? '?category=' + parent : ""}`);
  if (error) return { error, data: [] };
  return {
    data: categories,
    error: null,
  };
};